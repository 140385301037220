import React from 'react';
import { Link } from 'react-router-dom';

const BlogLink = ({ to, children, date }) => (
  <div className="flex justify-between items-baseline mb-3">
    <Link 
      to={to} 
      className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 border-b border-gray-300 dark:border-gray-600"
    >
      {children}
    </Link>
    <span className="text-sm text-gray-400 dark:text-gray-500 ml-4">
      {date}
    </span>
  </div>
);

const Blog = () => {
  return (
    <div className="flex flex-col items-center min-h-screen max-w-2xl mx-auto">
      {/* Thoughts Section */}
      <section className="w-full mb-20">
        <h2 className="text-2xl mb-6 text-gray-900 dark:text-gray-100">
          thoughts
        </h2>
        <div className="space-y-2">
          <BlogLink to="/blog/trip-across-america" date="01-13-2025">
            A trip across America
          </BlogLink>
          {/* <BlogLink to="/blog/ethical-vs-practical" date="01-03-2025">
            Ethical vs Practical
          </BlogLink>
          <BlogLink to="/blog/inward-vs-outward" date="01-04-2025">
            Inward vs Outward
          </BlogLink> */}
        </div>
      </section>

      {/* Reviews Section */}
      <section className="w-full">
        <h2 className="text-2xl mb-6 text-gray-900 dark:text-gray-100">
          reviews
        </h2>
        <div className="space-y-2">
          <BlogLink to="/blog/arcane-s2" date="Coming Soon">
            Arcane S2
          </BlogLink>
          <BlogLink to="/blog/red-rising-1-3" date="Coming Soon">
            Red Rising Saga 1-3
          </BlogLink>
          <BlogLink to="/blog/red-rising-4-6" date="Coming Soon">
            Red Rising Saga 4-6
          </BlogLink>
        </div>
      </section>
    </div>
  );
};

export default Blog;

