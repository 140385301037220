import React from 'react';

const BlogPost = ({ title, date, children, sidebar }) => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-4xl font-bold mb-4">{title}</h1>
      <p className="text-gray-600 mb-8">{date}</p>
      
      <div className="relative">
        <div className="prose max-w-none">
          {children}
        </div>
        
        {sidebar && (
          <div className="hidden lg:block lg:float-right lg:w-[300px] lg:ml-8">
            {sidebar}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPost; 