import React from 'react';

const EmptyPage = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <h1 className="text-2xl text-gray-600 dark:text-gray-400">
        Coming soon... (I promise I'm working on it)
      </h1>
    </div>
  );
};

export default EmptyPage; 