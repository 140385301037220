import { getImageUrl } from '../config/supabase';

const getBookCoverPath = (filename) => `images/book-covers/${filename}`;

// Static book data without the processed URLs
export const currentlyReading = [
  {
    id: 1,
    title: "Beyond Entrepreneurship 2.0",
    author: "Jim Collins and Bill Lazier",
    coverImagePath: getBookCoverPath("beyond-entrepreneurship.jpg"),
    progress: 26,
    rating: 4,
    goodreadsUrl: "https://www.goodreads.com/book/show/55270310-be-2-0-beyond-entrepreneurship-2-0"
  },
  {
    id: 2,
    title: "Frankenstein",
    author: "Mary Shelley",
    coverImagePath: getBookCoverPath("frankenstein.jpeg"),
    progress: 23,
    rating: 4,
    goodreadsUrl: "https://www.goodreads.com/book/show/64507108-frankenstein-or-the-modern-prometheus-by-mary-shelley-illustrated"
  },
  {
    id: "blink",
    title: "Blink",
    author: "Malcolm Gladwell",
    coverImagePath: getBookCoverPath("blink.jpg"),
    progress: 29,
    goodreadsUrl: "https://www.goodreads.com/book/show/40102.Blink"
  }
];

export const recentlyRead = [
  {
    id: 3,
    title: "Lightbringer",
    author: "Pierce Brown",
    coverImagePath: getBookCoverPath("lightbringer.jpeg"),
    rating: 5,
    goodreadsUrl: "https://www.goodreads.com/book/show/29227774-light-bringer"
  },
  {
    id: 4,
    title: "Dark Age",
    author: "Pierce Brown",
    coverImagePath: getBookCoverPath("dark_age.jpeg"),
    rating: 4,
    goodreadsUrl: "https://www.goodreads.com/book/show/29226553-dark-age"
  },
  {
    id: 5,
    title: "Man in the High Castle",
    author: "Philip K. Dick",
    coverImagePath: getBookCoverPath("man_in_the_high_castle.jpeg"),
    rating: 3,
    goodreadsUrl: "https://www.goodreads.com/book/show/23121747-the-man-in-the-high-castle"
  },
  {
    id: 6,
    title: "Iron Gold",
    author: "Pierce Brown",
    coverImagePath: getBookCoverPath("iron_gold.jpeg"),
    rating: 4,
    goodreadsUrl: "https://www.goodreads.com/book/show/33257757-iron-gold"
  },
  {
    id: 7,
    title: "Zero to One",
    author: "Peter Thiel and Blake Masters",
    coverImagePath: getBookCoverPath("zero_to_one.jpeg"),
    rating: 5,
    goodreadsUrl: "https://www.goodreads.com/book/show/18050143-zero-to-one"
  },
  {
    id: 8,
    title: "Tomorrow, and Tomorrow, and Tomorrow",
    author: "Gabrielle Zevin",
    coverImagePath: getBookCoverPath("tomorrow_three.jpeg"),
    rating: 4,
    goodreadsUrl: "https://www.goodreads.com/book/show/58784475-tomorrow-and-tomorrow-and-tomorrow"
  },
];

// Custom hook that combines the static data with Supabase URLs
export function useBookData() {
  const processBookData = (books) => {
    return books.map(book => ({
      ...book,
      coverImage: getImageUrl(book.coverImagePath)
    }));
  };

  return {
    currentlyReading: processBookData(currentlyReading),
    recentlyRead: processBookData(recentlyRead)
  };
}
