import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiMenu, HiX } from 'react-icons/hi';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/work', label: 'Work' },
    { path: '/blog', label: 'Blog' },
    { path: '/bookshelf', label: 'Bookshelf' },
  ];

  const isActive = (path) => location.pathname === path;

  return (
    <>
      {/* Mobile Nav Bar */}
      <div className="md:hidden fixed top-0 left-0 right-0 bg-gray-50 dark:bg-gray-800 z-50 border-b dark:border-gray-700">
        <div className="flex justify-between items-center p-4">
          <Link to="/" className="text-xl text-gray-900 dark:text-gray-100">
            Vamsi Saladi
          </Link>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            {isOpen ? <HiX size={24} /> : <HiMenu size={24} />}
          </button>
        </div>
        
        {isOpen && (
          <nav className="border-t dark:border-gray-700">
            {navItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`block px-4 py-3 ${
                  isActive(item.path)
                    ? 'bg-gray-100 dark:bg-gray-700'
                    : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
                onClick={() => setIsOpen(false)}
              >
                {item.label}
              </Link>
            ))}
          </nav>
        )}
      </div>

      {/* Desktop Nav Bar */}
      <nav className="hidden md:flex fixed top-0 left-0 right-0 h-16 bg-gray-50 dark:bg-gray-800 border-b dark:border-gray-700 items-center justify-between px-8 z-50">
        <div className="flex items-center">
          <Link to="/" className="text-xl text-gray-900 dark:text-gray-100">
            Vamsi Saladi
          </Link>
        </div>
        
        <div className="flex items-center justify-end flex-1 space-x-8">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`py-2 ${
                isActive(item.path)
                  ? 'font-semibold text-gray-900 dark:text-gray-100'
                  : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100'
              }`}
            >
              {item.label}
            </Link>
          ))}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
