import { useState, useEffect } from 'react';
import { getImageUrl } from '../config/supabase';

export const useSupabaseImage = (imagePath) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (imagePath) {
      setImageUrl(getImageUrl(imagePath));
    }
  }, [imagePath]);

  return imageUrl;
}; 