import React from 'react';
import BlogPost from '../../components/BlogPost';
import SpotifyEmbed from '../../components/SpotifyEmbed';
import { useSupabaseImage } from '../../hooks/useSupabaseImage';

const TripImage = ({ index }) => {
  const imagePath = `images/blogs/roadtrip_pics/${index}.jpg`;
  const imageUrl = useSupabaseImage(imagePath);
  
  return (
    <img 
      src={imageUrl} 
      alt={`Trip America ${index}`} 
      className="w-full h-auto"
      loading="lazy"
    />
  );
};

const TripAmerica = () => {
  return (
    <BlogPost 
      title="A trip across America" 
      date="2025-01-02"
    >
      <p>
        One of the best experience of my life was deciding to drive across America when I initially
        moved from Philly to San Francisco for work. I was 22 years old, and excited for a solo road trip.
        I loaded up my Volvo with all my earthly belonings, and started by driving down to Charlotte for 
        a friends birthday party. Once I had made my appearance, it was time to start my journey to San
        Francisco - accompanied by hours of podcasts, playlists, and audiobooks. 
      </p>

      <div className="relative">
        <h2>Charlotte to Nashville</h2>
        <div className="lg:float-right lg:w-[300px] lg:ml-8 mb-6">
          <SpotifyEmbed 
            type="track"
            assetPath="5Hroj5K7vLpIG4FNCRIjbP"
            title="Song of the day"
          />

        </div>
        <p>
          My first official stop on the drove was in Nashville, TN. I left Charlotte at 9 am in the morning,
          and drove through the day without stopping till I reached Nashville. I was tired, but I had downloaded
          multiple podcasts, playlists, and audiobooks to keep me entertained. 
        </p>
        <p>
          I arrived in Nashville around 10 pm, and checked into a hotel I found about an hour before getting to 
          the city. I was starving and went to a local pub to grab some food since it was one of the few places 
          still open. I remember walking vividly that the first day of the drive was a Sunday because I remember 
          walking into the pub and seeing a group of people gathered around a tv watching the Sunday Night Football 
          game featuring the Titans. 
        </p>

        <p>
          I ordered a burger and some fries, sitting at the bar to watch the game and because the rest of the 
          seating was filled. A man in a trench coat slid down next to me, holding a receipt for his order, 
          clearly waiting for his food. I gave him a quick nod and went back to watching the game. The man who slid next to me paused, leaned 
          over, and asked me "Are you a private detective?" I laughed, said no, and asked him why he asked. He said 
          he was in the bar because he was avoiding a private detective who was following him on his wife's behalf.
        </p>

        <p>
          Confused, I asked him why his wife hired a private detective. He said they were going throug a divorce,
          and that she was suspicious that he was cheating on her. Apparently, a few other men had revealed that
          they were private detectives in the past, and interrogated him on what he was doing at the place they 
          found him. Wanting to avoid another conversation like that, he asked me prior if I was one of the people 
          hired by his wife. After assuring him I was not, we started talking and learned a lot about each other. 
        </p>

        <p>
          For someone I would never see again, this man in a trench coat made quite an impression on me. His own 
          depression and anxiety from the divorce was written all over his face and he felt inspired to impart 
          some wisdom on me about marriage, love, and life. For what it was worth, I appreciated the conversation 
          greatly, and it remains to this day, one of the most interesting conversations I've had. 
        </p>
      </div>

      <div className="relative clear-both">
        <h2>Nashville to Kansas City</h2>
        <div className="lg:float-right lg:w-[300px] lg:ml-8 mb-6">
          <SpotifyEmbed 
            type="track"
            assetPath="5HQVUIKwCEXpe7JIHyY734?si=d30f39cbf92d47ec"
            title="Song of the day"
          />
        </div>
        <p>
          The next day, I woke up early and drove to St. Louis, MO. The real destination was Kansas City, 
          but I wanted to stop by St. Louis because it was on the way and because I'd never been to the 
          Gateway Arch. After a quick stop at the arch, some pictures, and some running around large 
          groups of people, I was on my way to Kansas City. 
        </p>

        <p>
          I arrived in Kansas City around 10 pm, and checked into a hotel. On the way into the city, I passed
          a massive Scientology building, what was both surprising and hilarious after 8 hours of driving. 
          I was excited to be in Kansas City. I walked around for a bit with Google Maps before ending up at a 
          sports bar, which had some great reviews. 
        </p>

        <p>
          The funniest part of my time in Kansas City was the sheer number of Josh Allen jerseys I saw. In the 
          sports bar I was eating at had a dedicated Josh Allen section, where it had a Patrick Mahomes and 
          Josh Allen jersey right next to each other and a ball from the 13 seconds playoff game. While the 
          rest of the country views these teams as bitter rivals, I got the impression a lot of people in KC 
          actually respected both the Bills and Josh Allen specifically. 
        </p>
      </div>

      <div className="relative clear-both">
        <h2>Kansas City to Denver</h2>
        <div className="lg:float-right lg:w-[300px] lg:ml-8 mb-6">
          <SpotifyEmbed 
            type="track"
            assetPath="02WDuAXXKumemrvsmrVBvH?si=961f2954ed104b45"
            title="Song of the day"
          />
        </div>
        <p>
          This was definitely the most boring part of the overall trip. Passing through the middle of 
          America was the first time in my life I saw so many acres of farmland. It felt like I was in a 
          totally different part of the world.
        </p>

        <p>
          But the closer I got to Denver, the more I started to worry about the rest of the trip - I was 
          about to drive into a snowstorm. About an hour away from the city, I stopped at a gas station 
          to find a hotel and fill up on gas. Pulling up to the hotel, I saw a crowd of people wearing 
          Nuggets jerseys. After checking in to the hotel, I realized there was a home game that night 
          against the Mavs. 
        </p>

        <p>
          It was probably not the right decision for the drive, but I decided to get tickets to the game 
          (which ended up only being 10 dollars because they were super far away) and ended up having a 
          blast with some locals. They offered to buy me dinner and welcomed me to the city as I explained
          my trip. As I left the arena, they gave me some advice on getting to my next destination - don't 
          drive thruogh the mountains and Colorado. Instead, go up through Wyoming and so I did.  
        </p>
      </div>

      <div className="relative clear-both">
        <h2>Denver to Salt Lake City</h2>
        <div className="lg:float-right lg:w-[300px] lg:ml-8 mb-6">
          <SpotifyEmbed 
            type="track"
            assetPath="285ieonEuLkll3zknYK2TY?si=98ca03415288459e"
            title="Song of the day"
          />
        </div>
        <p>
          Unknown to me, this was about to one of the most eventful days of my life. As I left Denver,
          I filled up on gas and started plotting my route through Wyoming. This might seem like an 
          obvious mistake, but I was not at all prepared for how sparsely populated Wyoming was. 
        </p>

        <p>
          About two and a half hours into the drive, I was at about a third of the gas tank left. Jamming
          out to some Disney tunes, I set my car into cruise control on a completely empty 1-lane highway
          and pulled out my phone to find a gas station on the way. The first indication of danger was the 
          fact that I had no cell service. I figured it was just this particular part of the highway, so 
          I kept driving but kept a lookout for any sort of exit or gas station.  
        </p>

        <p>
          After another 30 minutes, I started to get a little bit more nervous, but I finally had cell service.
          I called my parents and told them to track my location just in case, and I looked for gas stations
          nearby. The closest one was 150 miles away - in the opposite direction, back towards Denver.
        </p>

        <p>
          Now I was really panicking and kept driving while desperately looking for a small town or exit.
          Thankfully, I was on a highway that was massively elevated, so I had a nice view of the valley 
          to the side. After another 15 minutes of driving, I saw a small town, and a massive pole with what
          looked like gas prices. Excited, I pulled off the highway and drove into the town, using the giant 
          pole as a reference since Google Maps had nothing registered in the area.
        </p>

        <p>
          When I got the pole, I was relieved to see that it was indeed a gas station, even though it was rundown
          and looked a little suspicious. I pulled up and realized that every single gas pump was shut off and closed.
          I started to panic again. But attached to the gas station was a small convenience store and a barn. Desperate,
          I went up to the store. It was also closed. Even more desperate, I went up to the barn. I knocked on the door,
          praying to god someone wouldn't walk out with a shotgun. Instead, an elderly man opened the door after a few seconds,
          looking just as confused as I was. I told him my predicament and he came to my rescue.  
        </p>

        <p>
          Darren might have saved my life that day - after introducing himself as the owner of the store and gas station, he 
          said the pumps were shutoff since basically no one came on the highway this time of year. He told me to stay put and 
          got into his own truck, and drove 15 miles to a nearby gas resorvoir and filled up 12 gallons of gas for me into 3 
          cans and helped me fuel my car. He even gave me a sandwich before sending me on my way. Needless to say, I gave Darren 
          an extremely generous tip. 
        </p>

        <p> 
          By the time I got into Salt Lake City, it was snowing heavily and I could barely see the road. I found 
          a hotel at the edge of the city and immediately went to bed.
        </p>
      </div>

    
      <div className="relative clear-both">
        <h2>Salt Lake City to Tahoe</h2>
        <div className="lg:float-right lg:w-[300px] lg:ml-8 mb-6">
          <SpotifyEmbed 
            type="track"
            assetPath="28SPUzsMdzEyba3ffuPnrv?si=7e6a86c3d5384f82"
            title="Song of the day"
          />
        </div>
        <p>
          This was the one of the prettiest drives of the trip. Going through the mountains to end up at 
          Tahoe was so gorgeous and I was driving on highways with 90 mph speed limits. After what felt like
          a near death experience, I was excited to finally be in SF. I had downloaded some great playlists 
          and true-crime podcasts but I barely listened to them. The views were so stunning I kept stopping 
          to take pictures. 
        </p>

        <p>
          I arrived in Tahoe around 8 pm, and checked into a little hotel by the ski area. I was too exhausted, 
          to actually do anything but the place I grabbed food at had some fun live music so I stayed and met some
          folks who were there for a ski trip. But knowing it was my final day of driving, I stayed up a little
          late with folks and ended up waking up quite late as well.
        </p>
      </div>

      <div className="relative clear-both">
        <h2>Tahoe To Mountainview</h2>
        <div className="lg:float-right lg:w-[300px] lg:ml-8 mb-6">
          <SpotifyEmbed 
            type="track"
            assetPath="7nKBxz47S9SD79N086fuhn?si=a358eaff4cd64b9f"
            title="Song of the day"
          />
        </div>
        <p>
          After what felt like a lifetime of driving, I finally arrived in South Bay. I was moving 
          into a place by Rengstorff Park, close to where I would be working in Los Altos. I got my keys
          and officially moved in at the front desk but didn't do anything the rest of the day. I had a 
          sleeping bag and so I just set up wifi, watched some tv, gathered some photos and videos from 
          my dashcam, and crashed. 
        </p>

        <p>
          I'm probably in the minority when I say I really enjoy long drives, especially by myself, but this 
          was a truly once in a lifetime experience. I met some crazy people and some incredible people and really 
          had a ton of time to think about what I wanted in life as I made the move back to the west coast. I can
          truthfully say that driving over shifted my mindset for how I wanted to live in South Bay and really changed 
          my overall experience and time there. And for that, I'm eternally grateful I convinced my parents to go 
          on this crazy journey. 
        </p>
      </div>

      <div className="relative clear-both">
        <h2>Some fun pictures along the way</h2>
        <div className="grid grid-cols-2 gap-4">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
            <TripImage key={index} index={index} />
          ))}
        </div>
      </div>
    </BlogPost>
  );
};

export default TripAmerica; 