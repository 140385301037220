import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Work from './pages/Work';
import Bookshelf from './pages/Bookshelf';
import TripAmerica from './pages/blogs/TripAmerica';
import EmptyPage from './pages/EmptyPage';
import Layout from './components/Layout';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/work" element={<Work />} />
          <Route path="/bookshelf" element={<Bookshelf />} />
          <Route path="/blog/trip-across-america" element={<TripAmerica />} />
          <Route path="/blog/arcane-s2" element={<EmptyPage />} />
          <Route path="/blog/red-rising-1-3" element={<EmptyPage />} />
          <Route path="/blog/red-rising-4-6" element={<EmptyPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
