import React from 'react';

const SpotifyEmbed = ({ type, assetPath, title }) => {
  return (
    <div className="w-full">
      <iframe
        src={`https://open.spotify.com/embed/${type}/${assetPath}`}
        width="100%"
        height="152"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
        title={`Spotify ${type}: ${title}`}
      />
    </div>
  );
};

export default SpotifyEmbed; 